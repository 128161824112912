import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel';

@Component({
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    VueImageLightboxCarousel,
  },
})

export default class PropertyFloorplan extends Vue {
  @Prop({ default: (): any[] => [] })
  images!: [string, string][];

  @Watch('images.length')
  onImagesLengthChange(newLength: number, oldLength: number): void {
    if (newLength !== oldLength && this.$refs.gallery2) {
      // @ts-ignore
      this.$refs.gallery2.restart();
    }
  }

  defaultImagesFormatted: { path: string, caption: string }[] = [{
    path: require('@/assets/images/property/floorplans/floorplan1.jpg'),
    caption: 'floorplan1',
  }];

  showCarousel = false;

  get imagesFormatted(): { path: string, caption: string }[] {
    return this.images.length > 0
      ? this.images.map((image, i): { path: string, caption: string } => ({ path: (image[1]), caption: `Image ${i}` }))
      : this.defaultImagesFormatted;
  }

  openCarousel(img: number): void {
    this.showCarousel = true;
    // @ts-ignore
    this.$refs.lightbox.showImage(img);
  }
}
