import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import AccountUpgrade from '@/components/account/infobox/AccountUpgrade.vue';
import Footer from '@/components/common/footer/Footer.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { metaTitles, metaDescriptions, metaURL, metaImage, metaLocale, linkAlternate } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import { premiumAssetTemplate } from '@/helpers/premium-assets/template';
import { PropertyGallery, PropertyInfo, PropertyContent, PropertyMap, PropertyFloorplan } from './index';
import { name } from '../../../../whitelabel.config';

@Component({
  components: {
    BannerLegal,
    Navbar,
    AccountUpgrade,
    WithDownloads,
    PropertyGallery,
    PropertyInfo,
    PropertyContent,
    PropertyMap,
    PropertyFloorplan,
    Footer,
  },
})

export default class Property extends Vue {
  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    const description = this.asset ?
      this.$t('meta.property.description', {
        name: this.asset.name,
        address: this.assetAddress,
        min: this.asset.euroMin,
      }).toString() : '';

    const images = this.getDownloadsByPath({
      collection: 'assets',
      id: this.fundId,
      properties: 'images',
    });
    const firstImageURL = images ? images[0][1] : '';

    return {
      title: this.asset ? titleTemplate(this.asset.name) : name,
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaTitles(this.asset ? this.asset.name : ''),
        ...metaDescriptions(description),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
        ...firstImageURL && metaImage(firstImageURL),
      ],
    };
  }

  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass misc!: State['misc'];

  @Getter getDownloadsByPath!: Function;
  @Getter isUserLoggedIn!: boolean;

  /**
   * Redirecting to fund if we come to checkout from outside checkout path
   */
  mounted(): void {
    this.checkAssetExistsRedirect(this.assets);
  }

  /**
   * Check when assets is updated.
   */
  @Watch('assets')
  onAssetsChanges(newAssets: Asset[]): void {
    if (!this.$route.params.investmentId || !this.$route.params.paymentId) {
      this.checkAssetExistsRedirect(newAssets);
    }
  }

  /**
   * Check when we change the id.
   */
  @Watch('$route.params.id')
  onIdChanges(): void {
    if (!this.$route.params.investmentId || !this.$route.params.paymentId) {
      this.checkAssetExistsRedirect(this.assets);
    }
  }

  @Watch('isGTMDataAvail', { immediate: true })
  changeToisGTMDataAvail(val, oldVal): void {
    // check if the data is loaded and make sufficiently sure this is only executed once
    if (val && !oldVal) {
      this.$gtm.trackEvent({
        event: 'property_visit',
        email: this.user?.email,
        property: this.asset?.name,
      });
    }
  }

  get isGTMDataAvail(): boolean {
    return (!!this.user?.email || this.user === null) && !!this.asset?.name;
  }

  get fundId(): string {
    return this.$route.params.id;
  }

  /**
   * Get current asset.
   */
  get asset(): Asset | undefined {
    const foundAsset = this.assets.find((asset): any => asset.id === this.fundId);

    // Use fake data for premium assets if user is not logged in
    if (!this.isUserLoggedIn && foundAsset?.premium) {
      return { ...foundAsset, ...premiumAssetTemplate(this.$i18n.locale) };
    }

    return foundAsset;
  }

  get assetAddress(): string {
    if (!this.asset) {
      return '';
    }

    return this.asset.street && this.asset.houseNumber && this.asset.postalCode && this.asset.city
      ? `${this.asset.street} ${this.asset.houseNumber}, ${this.asset.postalCode}, ${this.asset.city}`
      : this.asset.city;
  }

  get hasImages(): boolean {
    return !!this.asset && !!this.asset.images && !!this.asset.images.length;
  }

  /**
   * Checking if the asset exists, redirecting if not.
   */
  checkAssetExistsRedirect(assets): void {
    if (assets.length > 0 && !this.asset) {
      this.$router.replace('/error/404');
    }
  }
}
