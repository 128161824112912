import { Vue, Component, Prop } from 'vue-property-decorator';
import { Asset } from '@/store/models/asset';
import ProgressBar from '@/components/common/progress-bar/ProgressBar.vue';

@Component({
  components: {
    ProgressBar,
  },
})
export default class PropertyContent extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Prop({ default: (): boolean => false }) userLoggedIn!: boolean;

  get shouldShowProgress(): boolean {
    const sold = this.asset.totalValueShares - this.asset.sharesAvailable;
    return (Math.round(((sold / this.asset.sharesAvailable) * 100) * 100) / 100) >= 80;
  }
}
